<!--设置管理模块--控制器管理页-->
<template>
  <div id="controllerEquipment">
    <!-- 页面公共标题栏 -->
    <div class="pageTitle">
      <div class="pageTitle-left">
        <img src="../../../../public/images/set/setindex06.png" />
        <span>{{ $t('controller.a1') }}</span>
      </div>
      <div>
        <a-icon type="close" :style="{ color: 'rgba(12, 12, 12, 0.65)' }" @click="Fallback"/>
      </div>
    </div>
    <!-- 导航栏 -->
    <div class="menu">
      <a-menu :selectedKeys="$route.name?[$route.name]:[]" mode="horizontal" @select="menuSelect">
        <a-menu-item key="controller-controller"> {{ $t('controller.a1') }} </a-menu-item>
        <a-menu-item key="controller-canonly" v-if="$platform.bivale=='HELC'"> {{ $t('edge.edge001') }} </a-menu-item>
      </a-menu>
    </div>
    <!-- 导航页面标题 -->
    <div>
      <my-PageTitle>{{ SecondaryTitle }}</my-PageTitle>
    </div>
    <!-- 导航页面容器 -->
    <div class="main">
      <transition name="slide-fade" mode="out-in">
          <router-view class="view"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import PageTitle from '../../../components/Title/PageTitle.vue'

export default {
  name: "equipment",  
  data() {
    return {
      loading: false, 
      name:this.$t('controller.a1'),
      activeKey:(this.$platform.bivale=='HELC'?'1':'2'),
      SecondaryTitle: this.$t('controller.a1')
    };
  },
  watch:{
    '$route':{
      handler(to,from){
        console.log('to',to)
        const name = to.name   
        if(name == 'controller-controller'){
          this.SecondaryTitle = this.$t('controller.a1')
        }else if(name == 'controller-canonly'){
          this.SecondaryTitle = this.$t('edge.edge001')
        }else{
          return ''
        }
      },
      deep: true,
      immediate: true,   
    }
  },
  methods: {
    // 关闭页面
    Fallback(){
      this.$router.push('/set/Home')
    },
    // 导航选择
    menuSelect(e){
      const key = e.key
      this.$router.push({
        name: key,
      });
    }
  },
  components: {
    'my-PageTitle':PageTitle
  },
};
</script>

<style scoped>
#controllerEquipment {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 10px #bdbcbc;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
} 
.pageTitle{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  padding: 10px 20px 0px 20px;
}
.pageTitle-left{
  display: flex;
  align-items: center;
}
.pageTitle img{
  width: 45px;
  margin-right: 10px;
}
.menu{
  padding: 0px 20px;
}
.main{
  width: 100%;
  height: calc(100% - 149.25px);
}
.view{
  position: absolute;
  width: 100%;
  height: calc(100% - 149.25px);
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>